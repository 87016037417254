import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { Paragraph } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import ColorsDataVisualisation, { DataIllustrations } from '~/components/ColorsDataVisualisation';
export const query = graphql`
query dataVisualQuery {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/datavisualisering"}, extension: {eq: "png"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <p>{`Entur har en egen palett for farger som skal benyttes til visualisering av data.
Datavisualiseringfargene våre må innfri andre krav enn de andre fargene våre gjør.
For det første så må de være såpass distinkte at data som skal visualiseres har høy lesbarhet,
slik at man enkelt kan se hvilken farge som tilhører hvilken data.
Denne lesbarheten skal også, så godt det lar seg gjøre, gjelde for personer med fargeblindhet.
I tillegg skal fargene, og måten vi bruker dem på, fortsatt formidle Enturs identitet.
Derfor er det viktig å benytte dem i riktig rekkefølge og mengde.`}</p>
    <p>{`Fargene innfrir kontrastkrav 3:1 for grafiske elementer i WCAG-kravene.`}<a parentName="p" {...{
        "href": "/universell-utforming/kontrast-sjekker"
      }}>{`Les mer om kontrastkrav til farger her`}</a>{`.
Fargene har også tilhørende tokens. `}<a parentName="p" {...{
        "href": "/tokens/fargetokens/oversikt-farger"
      }}>{`Oversikt over alle fargetokens`}</a>{` er tilgjengelig under Tokens-menypunktet.`}</p>
    <h2 {...{
      "id": "fargepalett"
    }}>{`Fargepalett`}</h2>
    <ColorsDataVisualisation mdxType="ColorsDataVisualisation" />
    <div style={{
      height: '32px'
    }}></div>
    <p>{`Fargene har også tilhørende tokens. Oversikt over alle fargetokens er tilgjengelig under Tokens-menypunktet](/tokens/fargetokens/oversikt-farger).`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <h3 {...{
      "id": "vektlegging-av-datavisualiseringfarger"
    }}>{`Vektlegging av datavisualiseringfarger`}</h3>
    <p>{`Når man benytter seg av denne fargepaletten så er det viktig at vektingen av fargene opprettholder den visuelle profilen til Entur.
Derfor er rekkefølgen på fargene over også en rangering på hvor mye av hver farge det burde være.
Det vil si bruk mest av `}<inlineCode parentName="p">{`Blue`}</inlineCode>{` og `}<inlineCode parentName="p">{`Coral`}</inlineCode>{`, og minst av `}<inlineCode parentName="p">{`Spring`}</inlineCode>{` og `}<inlineCode parentName="p">{`Lilac`}</inlineCode>{`.`}</p>
    <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "DatavisualiseringVektlegging")?.childImageSharp.fluid} alt="Vektleggingen av datavisualiserings-fargene." preset="full-width-image" mdxType="ImageDisplay" />
    <h3 {...{
      "id": "eksempel-1"
    }}>{`Eksempel 1`}</h3>
    <p>{`I dette søylediagtrammet er det kun to dataverdier,
og disse bruker forholdsvis `}<inlineCode parentName="p">{`Blue`}</inlineCode>{` og `}<inlineCode parentName="p">{`Coral`}</inlineCode>{`, fordi dette er de to viktigste fargene i fargepaletten.`}</p>
    <DataIllustrations illustration="1" mdxType="DataIllustrations" />
    <h3 {...{
      "id": "eksempel-2"
    }}>{`Eksempel 2`}</h3>
    <p>{`Her er et kakediagram som inneholder flere typer data.
Da prøver man å benytte fargene slik at det er overvekt av `}<inlineCode parentName="p">{`Blue`}</inlineCode>{` og `}<inlineCode parentName="p">{`Coral`}</inlineCode>{` sammenlignet med de andre fargene.
`}<inlineCode parentName="p">{`Jungle`}</inlineCode>{` og `}<inlineCode parentName="p">{`Azure`}</inlineCode>{` blir her brukt som supplerende farger, da de kommer som farge 3 og farge 4 i paletten.`}</p>
    <DataIllustrations illustration="2" mdxType="DataIllustrations" />
    <h3 {...{
      "id": "eksempel-3"
    }}>{`Eksempel 3`}</h3>
    <p>{`Her er et linjediagram, og i linjediagram så er det "like mye" av hver farge.
Vi benytter fortsatt fargene i den oppsatte rekkefølgen.`}</p>
    <DataIllustrations illustration="4" mdxType="DataIllustrations" />
    <h3 {...{
      "id": "eksempel-4"
    }}>{`Eksempel 4`}</h3>
    <p>{`I noen søylediagrammer vil mengden av dataverdiene variere fra søyle til søyle.
Mange ulike kategorier gjør at vi må benytte alle fargene i paletten, men ettersom datamengden varierer i de ulike søylene er det vanskelig å vektlegge Blue og Coral.
Vi har valgt å benytte fargene i den oppsatte rekkefølgen, men her er det best å teste seg frem.
Det er anbefalt å benytte mest av `}<inlineCode parentName="p">{`Blue`}</inlineCode>{` og `}<inlineCode parentName="p">{`Coral`}</inlineCode>{`, men det er ikke alltid mulig for mer intrikate datasett.`}</p>
    <DataIllustrations illustration="3" mdxType="DataIllustrations" />
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="warning" fluidSource={props.data.files.images.find(image => image.name === "dataDont1")?.childImageSharp.fluid} alt="Søylediagram med tekst på søylene" mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Unngå tekst oppå former i diagrammet</Paragraph>
  </DoDontCard>
  <DoDontCard variant="warning" fluidSource={props.data.files.images.find(image => image.name === "dataDont2")?.childImageSharp.fluid} alt="Søylediagram med " mdxType="DoDontCard">
    <Paragraph mdxType="Paragraph">Unngå å benytte mest av de lavest prioriterte fargene</Paragraph>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      